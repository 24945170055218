
import { Vue, Component } from "vue-property-decorator"
import { FetchResult } from "apollo-link"
import AppPage from "@/components/layout/AppPage.vue"
import UserChip from "@/components/content/UserChip.vue"

import {
  MissionStats,
  MissionDashboardStatisticsQuery,
  MissionDashboardStatisticsQueryQuery,
  MissionLifecycle,
  MissionLifecyclesQuery,
} from "@/gql"
import Loader from "@/components/widgets/common/Loader.vue"

@Component({
  components: {
    UserChip,
    AppPage,
    Loader,
  },
})
export default class MissionDashboardPage extends Vue {
  readonly query = MissionDashboardStatisticsQuery
  readonly missionsQuery = MissionLifecyclesQuery

  MissionStats: MissionStats[] | null = null

  headers = [
    {
      text: "Name",
      value: "name",
      custom: true,
    },
    {
      text: "Client",
      value: "client",
      custom: true,
      sortable: false,
    },
    {
      text: "Practice Group",
      value: "practiceGroup",
      custom: true,
      sortable: false,
    },
    {
      text: "Stage",
      value: "state",
      custom: true,
      sortable: false,
    },
    {
      text: "Mission Partner",
      value: "partner",
      custom: true,
      sortable: false,
    },
    {
      text: "Mission Executive",
      value: "executive",
      sortable: false,
      custom: true,
    },
  ]

  onResult(result: FetchResult<MissionDashboardStatisticsQueryQuery>) {
    if (result.data) {
      this.MissionStats = result.data.missionDashboardStatistics
    }
  }

  gotoDetailPage(mission: MissionLifecycle) {
    this.$router.push({
      name: this.$routes.MissionDetail,
      params: { id: mission.id.toString() },
    })
  }
}
