
import { Component, Vue } from "vue-property-decorator"
import {
  CountriesQuery,
  MissionLifecycle,
  ListClientsQuery,
  ConsultantsSearchQuery,
  MissionLifecyclesListQuery,
} from "@/gql"
import AddLeadForm from "@/components/forms/AddLeadForm.vue"
import UserChip from "@/components/content/UserChip.vue"
import moment from "moment"
import AppPage from "@/components/layout/AppPage.vue"
import { ActionType } from "@/components/widgets/common/FilterBar.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import Table from "@/components/widgets/data/Table.vue"

@Component({ components: { AppPage, AddLeadForm, UserChip } })
export default class Missions extends Vue {
  showApplicationsModal = true
  readonly countriesQuery = CountriesQuery
  readonly listClientsQuery = ListClientsQuery
  readonly consultantsSearchQuery = ConsultantsSearchQuery

  readonly moment = moment
  readonly query = MissionLifecyclesListQuery
  showAddForm = false
  queryVars: { [key: string]: any } | null = null
  sort: { [key: string]: string } = { createdAt: "desc" }
  paginate: { per: number; page: number } = {
    page: 1,
    per: 6,
  }
  headers = [
    {
      text: "Name",
      value: "name",
      custom: true,
      width: "25%",
    },
    {
      text: "Client",
      value: "client",
      custom: true,
      sortable: false,
    },
    {
      text: "Service",
      value: "scopeService",
      custom: true,
      sortable: false,
    },
    {
      text: "Stage",
      value: "state",
      custom: true,
      sortable: false,
    },
    {
      text: "Mission Manager",
      value: "manager",
      sortable: false,
      custom: true,
    },
  ]

  surveyFeatures = [
    "Create new surveys",
    "View and duplicate existing surveys",
    "View and analyse survey results",
  ]
  itemActions!: ActionType

  get queries(): RefetchQueryDescription {
    return ["missionLifecyclesQuery"]
  }

  gotoDetailPage(mission: MissionLifecycle) {
    this.$router.push({
      name: this.$routes.MissionDetail,
      params: { id: mission.id.toString() },
    })
  }

  created() {
    this.itemActions = {
      "View mission": (mission: MissionLifecycle) => {
        this.gotoDetailPage(mission)
      },
    }
  }

  mounted() {
    this.queryVars = (this.$refs.table as Table).queryVars
  }

  getStateColor(state: string) {
    let color = ""
    switch (state) {
      case "lead_stage":
      case "scoping_stage":
        color = "brown lighten-5 brown--text text--darken-3"
        break

      case "contracting_stage":
      case "proposal_stage":
        color = "yellow lighten-5 yellow--text text--darken-3"
        break

      case "staffing_stage":
        color = "lime lighten-5 lime--text text--darken-3"
        break

      case "execution_stage":
        color = "light-green lighten-5 light-green--text text--darken-3"
        break

      case "completed":
        color = "teal lighten-5 teal--text text--darken-3"
        break

      default:
        color = "grey lighten-2"
        break
    }

    return color
  }
}
