
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import {
  Client,
  MissionLead,
  LeadGradesQuery,
  LeadSourcesQuery,
  ClientsSearchQuery,
  PracticeGroupsQuery,
  MissionNaturesQuery,
  CreateMissionLeadMutation,
  UpdateMissionLeadMutation,
  CreateMissionLeadMutationMutation,
  UpdateMissionLeadMutationMutation,
  CreateMissionLeadMutationMutationVariables,
  UploadFileInput,
  MissionLifecyclesQueryQuery,
  LeadSource,
  PracticeGroup,
  MissionLifecyclesListQuery,
  UploadFile,
  DeleteUploadFilePayload,
  DeleteUploadFileMutation,
  RegionsQuery,
  ClientContact,
} from "@/gql"
import CountrySelect from "@/components/widgets/input/CountrySelect.vue"
import ClientSelect from "@/components/widgets/input/ClientSelect.vue"
import AddClientForm from "@/components/forms/AddClientForm.vue"
import DocumentUploader from "@/components/widgets/input/DocumentUploader.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"

type AddLeadInput = {
  summary: string
  projectLocationCountry: string | null
  regionId: number | null
  projectLocationType: string | null
  tentativeStartDate: string | null
  tentativeEndDate: string | null
  leadGradeId: number | null
  leadSource: LeadSource | null
  practiceGroup: PracticeGroup | null
  // termsOfReference: UploadFileInput | null
  termsOfReferences: UploadFileInput[] | null
  clientId: number | null | undefined
  clientContactName: string | null
  clientContactRole: string | null
  clientContactPhone: string | null
  clientContactEmail: string | null
  eoiDeadline?: string | null
  rfpDeadline?: string | null
  subPracticeGroupId: number | null
  tenderObjective: string | null
  saveClientContact: boolean
}

@Component({
  components: {
    CountrySelect,
    ClientSelect,
    AddClientForm,
    DocumentUploader,
    DocumentCard,
  },
})
export default class AddLeadForm extends Vue {
  @Prop() queries?: RefetchQueryDescription
  @Prop() queryVars?: { [key: string]: any }
  @Prop() lead!: MissionLead

  readonly MissionNaturesQuery = MissionNaturesQuery
  readonly LeadSourcesQuery = LeadSourcesQuery
  readonly RegionsQuery = RegionsQuery
  readonly PracticeGroupsQuery = PracticeGroupsQuery
  readonly LeadGradesQuery = LeadGradesQuery

  uploadedTOR: UploadFile[] = []

  activeClient: Client | null = null

  hasClientContact = false
  useSavedContact = false
  showAddClientDialog = false
  showUploadModal = false
  isSaving = false
  hasTORChanged = false

  resetForm() {
    this.hasTORChanged = false
    this.$set(this, "form", { ...this.defaultForm })
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  get summaryWordCount() {
    return this.form.summary.split(/\s+/).filter(function (n) {
      return n != ""
    }).length
  }

  defaultForm: AddLeadInput = {
    summary: "",
    projectLocationCountry: null,
    regionId: null,
    projectLocationType: null,
    tentativeStartDate: null,
    tentativeEndDate: null,
    leadGradeId: null,
    leadSource: null,
    practiceGroup: null,
    termsOfReferences: null,
    clientId: null,
    clientContactName: null,
    clientContactRole: null,
    clientContactPhone: null,
    clientContactEmail: null,
    eoiDeadline: null,
    rfpDeadline: null,
    subPracticeGroupId: null,
    tenderObjective: null,
    saveClientContact: false,
  }

  form: AddLeadInput = { ...this.defaultForm }
  clientsSearchQuery = [
    {
      query: ClientsSearchQuery,
    },
  ]

  @Watch("lead", { immediate: true })
  populateForm() {
    if (this.lead) {
      for (let field in this.form) {
        let current = this.lead[field as keyof MissionLead]
        if (current) (this.form[field as keyof AddLeadInput] as any) = current
      }

      this.activeClient = this.lead.client ? this.lead.client : null
      this.form.leadSource = this.lead.leadSource!
      this.form.regionId = this.lead.region ? this.lead.region.id : 0
      this.form.leadGradeId = this.lead.leadGrade ? this.lead.leadGrade.id : 0
      this.form.clientId = this.lead.client ? this.lead.client.id : 0

      this.form.subPracticeGroupId = this.lead.subPracticeGroup?.id ?? 0
      this.form.termsOfReferences = null
      this.uploadedTOR = this.lead.termsOfReferences

      this.form.tenderObjective = this.lead.eoiDeadline
        ? "eoi"
        : this.lead.rfpDeadline
        ? "rfp"
        : null
    }
  }

  async createLead(leadData: CreateMissionLeadMutationMutationVariables) {
    try {
      this.isSaving = true
      // Mutate
      const result = await this.$apollo.mutate<
        CreateMissionLeadMutationMutation,
        CreateMissionLeadMutationMutationVariables
      >({
        mutation: CreateMissionLeadMutation,
        variables: {
          ...leadData,
        },
        update: (cache, { data }) => {
          if (data && data.createMissionLead.missionLifecycle) {
            const query = MissionLifecyclesListQuery

            try {
              // fetch missionlifeccyles from cache
              const cacheData = cache.readQuery<MissionLifecyclesQueryQuery>({
                query,
                variables: this.queryVars,
              })
              // prepend created missionlifecyle
              cacheData?.missionLifecycles.data.unshift(data.createMissionLead.missionLifecycle)
              // write query to cache
              cache.writeQuery({ query, variables: this.queryVars, data: cacheData })
            } catch (error) {
              console.error(error)
            }
          }
        },
      })

      // Done
      if (result.data) {
        if (result.data.createMissionLead.error) {
          this.addMutationError(result.data.createMissionLead.error)
        } else {
          this.addSuccess("Lead added successfully!")
          this.resetForm()

          this.$emit("save")
          this.$emit("close")
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.isSaving = false
    }
  }

  async updateLead(leadData: CreateMissionLeadMutationMutationVariables) {
    this.isSaving = true

    let { termsOfReferences, ...rest } = leadData.missionLead

    // Mutate
    const result = await this.mutate<UpdateMissionLeadMutationMutation>({
      mutation: UpdateMissionLeadMutation,
      variables: {
        missionLifecycleId: this.lead.missionLifecycle.id,
        missionLead: this.hasTORChanged
          ? { ...rest, termsOfReferences: this.form.termsOfReferences }
          : rest,
      },
      refetchQueries: this.queries,
      done: () => {
        this.isSaving = false
      },
    })

    if (result.data && !result.data.updateMissionLead.error) {
      this.addSuccess("Lead updated successfully!")
      this.resetForm()

      this.$emit("save")
      this.$emit("close")
    }
  }

  async onSave() {
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) {
      return
    }

    this.isSaving = true

    let { leadSource, practiceGroup, tenderObjective, ...rest } = this.form

    // Request payload
    const leadData: CreateMissionLeadMutationMutationVariables = {
      missionLead: {
        ...rest,
        rfpDeadline: this.form.tenderObjective === "rfp" ? this.form.rfpDeadline : null,
        eoiDeadline: this.form.tenderObjective === "eoi" ? this.form.eoiDeadline : null,
        leadSourceId: this.form.leadSource!.id,
        practiceGroupId: this.form.practiceGroup!.id,
        subPracticeGroupId: this.form.subPracticeGroupId,
        saveClientContact: this.form.saveClientContact,
      },
    }

    if (!this.lead) {
      // Create
      await this.createLead(leadData)
    } else {
      // Update
      await this.updateLead(leadData)
    }
  }

  setUploadedTOR(docs: UploadFileInput[]) {
    this.form.termsOfReferences = docs
    this.hasTORChanged = true
  }

  deleteFile(file: UploadFile) {
    this.onDeleteFile(file)
  }

  removeFileInput(file: UploadFileInput) {
    this.form.termsOfReferences = this.form.termsOfReferences!.filter((f) => f.key !== file.key)
  }

  async onDeleteFile(file: UploadFile) {
    if (!file.id) return
    const result = await this.mutate<DeleteUploadFilePayload>({
      mutation: DeleteUploadFileMutation,
      variables: {
        id: Number(file.id),
      },
      refetchQueries: this.queries,
    })

    if (result.data && !result.data.error) {
      this.addSuccess("Uploaded File deleted successfully")
    }
  }

  onSaveClientComplete(client: Client) {
    this.form.clientId = client.id
    this.showAddClientDialog = false
  }

  @Emit("input")
  @Emit("close")
  onCancel() {
    return false
  }

  sanitizeClientContact() {
    this.form.clientContactName = null
    this.form.clientContactRole = null
    this.form.clientContactEmail = null
    this.form.clientContactPhone = null
  }

  prefillClientContact(clientContact: ClientContact | undefined) {
    if (!clientContact) return
    this.form.clientContactName = clientContact.name
    this.form.clientContactRole = clientContact.role
    this.form.clientContactEmail = clientContact.email
    this.form.clientContactPhone = clientContact.phone!
  }

  @Watch("useSavedContact")
  watchSavedContact() {
    let clientContact = this.activeClient?.primaryClientContact || undefined
    if (this.useSavedContact) {
      this.prefillClientContact(clientContact)
    } else {
      this.sanitizeClientContact()
    }
  }

  @Watch("activeClient")
  loadClientContact() {
    if (this.activeClient) {
      this.form.clientId = this.activeClient?.id
      let clientContact = this.activeClient.primaryClientContact

      if (clientContact) {
        this.hasClientContact = true
        this.useSavedContact = true
        this.prefillClientContact(clientContact)
      } else {
        this.hasClientContact = false
        this.useSavedContact = false
        this.sanitizeClientContact()
      }
    }
  }
}
